/* eslint-disable no-promise-executor-return */
import { useState, useContext, useEffect } from 'react';
import AppContext from '../../appContext';
import { callApi } from '../../api/axios';
import { StakingContractUSDT } from '../../api/contracts';
import { fromSun } from '../helpers';

export function useGetBitcoinStakingData(wallet) {
  const { dispatch } = useContext(AppContext);
  const [rows, setRows] = useState([]);
  const { tronWeb } = window;
  // eslint-disable-next-line no-underscore-dangle
  const toDecimal = (value) => tronWeb.toDecimal(value?._hex);

  async function fetchData(depositIndex, delay) {
    try {
      const stakingContract = await tronWeb.contract(
        StakingContractUSDT.contractAbi.entrys,
        StakingContractUSDT.contractAddress,
      );
      const depositTariffId = await stakingContract.getDepositTariff(wallet, depositIndex).call();
      await new Promise((resolve) => setTimeout(resolve, delay));
      const depositDate = await stakingContract.getDepositAt(wallet, depositIndex).call();
      await new Promise((resolve) => setTimeout(resolve, delay));
      const depositAmount = await stakingContract.getDepositAmount(wallet, depositIndex).call();
      await new Promise((resolve) => setTimeout(resolve, delay));
      const depositPercentsMonthly = await stakingContract.calcDepositPercentsMonthly(
        wallet,
      ).call();
      await new Promise((resolve) => setTimeout(resolve, delay));
      const depositPercentsSeconds = await stakingContract.calcDepositPercentsEverySecond(
        wallet,
      ).call();
      await new Promise((resolve) => setTimeout(resolve, delay));
      const depositPaidOut = await stakingContract.getDepositPaidOut(wallet, depositIndex).call();
      await new Promise((resolve) => setTimeout(resolve, delay));
      const depositClosed = await stakingContract.getDepositClosed(wallet, depositIndex).call();

      const { data } = await callApi({
        method: 'get',
        url: `/b/tokens/staked/${wallet}/4/${toDecimal(depositAmount)}`,
      });

      setRows((prev) => ([
        ...prev,
        {
          depositId: depositIndex,
          tariffId: depositTariffId,
          date: toDecimal(depositDate) * 1000,
          wholeDeposit: fromSun(toDecimal(depositAmount)),
          gif: data[0]?.token_preview_file,
          tariffTitle: data[0]?.token_name,
          monthly: fromSun(toDecimal(depositPercentsMonthly[depositIndex])),
          income: fromSun(toDecimal(depositPercentsSeconds[depositIndex])),
          paidOut: fromSun(toDecimal(depositPaidOut)),
          isClosed: depositClosed,
        },
      ]));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  function delayedForLoop(iterations, delay) {
    let currentIteration = 0;

    async function next() {
      if (currentIteration < iterations) {
        await fetchData(currentIteration, 200);
        currentIteration += 1;
        setTimeout(next, delay);
      } else {
        dispatch({ type: 'toggleLoadingState', payload: false });
      }
    }

    next(); // Start the loop
  }

  useEffect(() => {
    const fetchNDeposits = async () => {
      if (wallet) {
        try {
          const { numDeposits } = await tronWeb.contract(
            StakingContractUSDT.contractAbi.entrys,
            StakingContractUSDT.contractAddress,
          ).investors(wallet).call();
          delayedForLoop(numDeposits, 1000);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    };

    fetchNDeposits();
  }, [wallet]);

  return { rows };
}
