import React from 'react';

const AppContext = React.createContext({
  variant: 'dark',
});

AppContext.displayName = 'AppContext';

export const AppStateProvider = AppContext.Provider;

export default AppContext;
