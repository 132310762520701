export const ADMIN_WALLETS = ['TKCAeJwNgoH1uzR31NE5QH1QTHE8YYYAfN', 'TSGaYuK6HnRYJMkwhkp434uh9fv66tDWXU', 'TRSnwp31gmFCpACn84EfdrtQorKToYaH6V'];

export const ROUTES = ['market', 'collections', 'stake', 'my', 'create'];

export const FULL_COLECTION_DATA = {
  3: {
    collection_name: 'Smart-Tron WORLD EXCHANGE',
    collection_id: 3,
    collection_img: '/assets/collection-img/trx.svg',
    currency: 'trx',
    contracts: {
      market: {
        address: 'TFgMrJAK5dCpZ5NwhmzoWST6E3qqVTzcpH',
      },
      create: {
        address: 'TLR4qiMCXRgMnRz4E8gCwGzxRQeMfSR4yk',
      },
      staking: {
        address: 'TP4AwG55auc2MMHZLc3dmRXh7G3afkTksC',
      },
    },
    coins: [
      {
        emission: 500, name: '18 months', staking: { percents: 9, months: 18 }, fixedPrice: false, price: 10000, stakingPrice: 10000, stakingTariff: 1,
      },
      {
        emission: 250, name: 'Euro_Gold', fixedPrice: false, price: 3000, stakingPrice: 1111,
      },
      {
        emission: 100, name: 'Map_Gold', fixedPrice: false, price: 25000,
      },
      {
        emission: 1000, name: '12 months', staking: { percents: 8, months: 12 }, fixedPrice: false, price: 3000, stakingPrice: 3000, stakingTariff: 0,
      },
      {
        emission: 500, name: 'Euro_Silver', fixedPrice: false, price: 1000, stakingPrice: 375,
      },
      {
        emission: 200, name: 'Map_Silver', fixedPrice: false, price: 10000,
      },
      {
        emission: 100, name: 'Esthete', fixedPrice: false, price: undefined,
      },
    ],
    rewardCoin: { name: 'Esthete', description: 'All 6 coins from collection' },
    stakingTariffs: [
      { id: 0, percents: 8, months: 12 },
      { id: 1, percents: 9, months: 18 },
      { id: 2, percents: 1, months: 12 },
      { id: 3, percents: 1, months: 18 },
      { id: 4, percents: 8, months: 3 },
      { id: 5, percents: 9, months: 3 },
    ],
  },
  4: { // FIXME: temporary
    collection_name: 'Bitcoin',
    collection_id: 4,
    collection_img: '/assets/collection-img/bitcoin.svg',
    currency: 'usdt',
    contracts: {
      market: {
        address: 'TUe87rGz2F2rFzB3oXi8m8itMNhjg2YgfE',
      },
      create: {
        address: 'TLR4qiMCXRgMnRz4E8gCwGzxRQeMfSR4yk', // stays the same
      },
      staking: {
        address: 'TT9iHjN8bfqytanK8XazweHTcDF114G8ds',
      },
    },
    coins: [
      {
        emission: 2000, name: 'Crypto', /* staking: { percents: 8, months: 12 }, */ fixedPrice: true, price: 0.05,
      },
      {
        emission: 500, name: 'Crystal', fixedPrice: true, price: 0.015,
      },
      {
        emission: 500, name: 'Petrol', fixedPrice: true, price: 0.015,
      },
      {
        emission: 500, name: 'Solar', fixedPrice: true, price: 0.015,
      },
      {
        emission: 2000, name: 'Stairs', /* staking: { percents: 9, months: 18 }, */ fixedPrice: true, price: 0.05,
      },
      {
        emission: 500, name: 'Water', fixedPrice: true, price: 0.015,
      },
      {
        emission: 500, name: 'Wind', fixedPrice: true, price: 0.015,
      },
      {
        emission: 300, name: 'Platinum', fixedPrice: true, price: undefined,
      },
    ],
    rewardCoin: { name: 'Platinum', description: 'All 7 coins from collection' },
    stakingTariffs: [
      { id: 0, percents: 3, months: 3 },
      { id: 1, percents: 6, months: 6 },
      { id: 2, percents: 9, months: 9 },
      { id: 3, percents: 9, months: 3 },
      { id: 4, percents: 9, months: 6 },
      { id: 5, percents: 9, months: 9 }],
  },
};
