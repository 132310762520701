import { useEffect, useContext } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import AppContext from '../../../appContext';

export default function ToastAutoHide() {
  const { appGlobalState, dispatch } = useContext(AppContext);

  const closeToast = () => dispatch({ type: 'setToast', payload: null });

  useEffect(() => {
    const timeout = setTimeout(
      () => { closeToast(); },
      1000 * (appGlobalState.toast?.visible || 5),
    );
    return () => clearTimeout(timeout);
  }, [appGlobalState.toast]);
  // console.log(document.body.getBoundingClientRect());
  return (
    <ToastContainer
      className="p-3 mb-3"
      // position="middle-center"
      style={{
        zIndex: '1100',
        position: 'absolute',
        top: document.body.getBoundingClientRect().top * -1,
        right: '0',
      }}
    >
      <Toast
        // show={appGlobalState.toast?.visible || false}
        show={appGlobalState.toast !== null}
        onClose={() => closeToast()}
        animation
        bg="dark"
      >
        <Toast.Header>
          <img
            src="/logo16.svg"
            width={20}
            height={20}
            className="rounded me-2"
            alt="img-to-be-here"
          />
          <strong className="me-auto">{appGlobalState.toast?.callee}</strong>
        </Toast.Header>
        <Toast.Body>{appGlobalState.toast?.body || ''}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
