const contractAddress = 'TLR4qiMCXRgMnRz4E8gCwGzxRQeMfSR4yk';
const contractAddressHex = '4154494b6f3ce4d597e269a45af4f269f510a1fea6';
const contractAbi = {
  entrys: [{ stateMutability: 'Nonpayable', type: 'Constructor' }, { inputs: [{ indexed: true, name: 'owner', type: 'address' }, { indexed: true, name: 'approved', type: 'address' }, { indexed: true, name: 'tokenId', type: 'uint256' }], name: 'Approval', type: 'Event' }, { inputs: [{ indexed: true, name: 'owner', type: 'address' }, { indexed: true, name: 'operator', type: 'address' }, { name: 'approved', type: 'bool' }], name: 'ApprovalForAll', type: 'Event' }, { inputs: [{ indexed: true, name: 'account', type: 'address' }, { name: 'amount', type: 'uint256' }], name: 'Deposit', type: 'Event' }, { inputs: [{ indexed: true, name: 'tokenId', type: 'uint256' }, { indexed: true, name: 'mintedBy', type: 'address' }, { indexed: true, name: 'mintedTo', type: 'address' }], name: 'Mint', type: 'Event' }, { name: 'MintingDisabled', type: 'Event' }, { name: 'MintingEnabled', type: 'Event' }, { inputs: [{ indexed: true, name: 'from', type: 'address' }, { indexed: true, name: 'to', type: 'address' }, { indexed: true, name: 'tokenId', type: 'uint256' }], name: 'Transfer', type: 'Event' }, { inputs: [{ indexed: true, name: 'account', type: 'address' }, { name: 'amount', type: 'uint256' }], name: 'Withdraw', type: 'Event' }, {
    inputs: [{ name: '_tokenId', type: 'uint256' }], name: 'FreezeToken', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'MINTING_LIMIT', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: '_contractOwner', type: 'address' }], name: 'TransferOwnership', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: '_tokenId', type: 'uint256' }], name: 'UnFreezeToken', stateMutability: 'Nonpayable', type: 'Function',
  }, { name: '_disableMinting', stateMutability: 'Nonpayable', type: 'Function' }, { name: '_enableMinting', stateMutability: 'Nonpayable', type: 'Function' }, {
    inputs: [{ name: 'newPrice', type: 'uint256' }], name: '_setMintingPrice', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: '_tokensMinter', type: 'address' }], name: '_setTokensMinter', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: '_approved', type: 'address' }, { name: '_tokenId', type: 'uint256' }], name: 'approve', stateMutability: 'Payable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: '_owner', type: 'address' }], name: 'balanceOf', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'firstStage', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'firstStageDenom', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'firstStageHolders', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'firstStageNom', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'firstStagePool', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'address' }], inputs: [{ name: '_tokenId', type: 'uint256' }], name: 'getApproved', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'getNotMintedAmount', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: '_tokenId', type: 'uint256' }], name: 'getTokenIsFrozen', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256[]' }], inputs: [{ name: '_user', type: 'address' }], name: 'getUserTokens', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: '_owner', type: 'address' }, { name: '_operator', type: 'address' }], name: 'isApprovedForAll', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], name: 'isMinterRole', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], name: 'isMintingEnabled', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256[20]' }], name: 'mint', stateMutability: 'Payable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'mintingPrice', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'string' }], name: 'name', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'address' }], inputs: [{ name: '_tokenId', type: 'uint256' }], name: 'ownerOf', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: '_from', type: 'address' }, { name: '_to', type: 'address' }, { name: '_tokenId', type: 'uint256' }], name: 'safeTransferFrom', stateMutability: 'Payable', type: 'Function',
  }, {
    inputs: [{ name: '_from', type: 'address' }, { name: '_to', type: 'address' }, { name: '_tokenId', type: 'uint256' }, { name: '_data', type: 'bytes' }], name: 'safeTransferFrom', stateMutability: 'Payable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'secondStage', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'secondStageDenom', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'secondStageHolders', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'secondStageNom', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'secondStagePool', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: '_operator', type: 'address' }, { name: '_approved', type: 'bool' }], name: 'setApprovalForAll', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: 'uri_', type: 'string' }], name: 'setTokenURI', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: '_interfaceID', type: 'bytes4' }], name: 'supportsInterface', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'string' }], name: 'symbol', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: '_index', type: 'uint256' }], name: 'tokenByIndex', stateMutability: 'Pure', type: 'Function',
  }, {
    outputs: [{ name: '_tokenId', type: 'uint256' }], inputs: [{ name: '_owner', type: 'address' }, { name: '_index', type: 'uint256' }], name: 'tokenOfOwnerByIndex', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'string' }], inputs: [{ name: '_tokenId', type: 'uint256' }], name: 'tokenURI', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'totalSupply', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: '_from', type: 'address' }, { name: '_to', type: 'address' }, { name: '_tokenId', type: 'uint256' }], name: 'transferFrom', stateMutability: 'Payable', type: 'Function',
  }, {
    outputs: [{ type: 'string' }], name: 'uri', stateMutability: 'View', type: 'Function',
  }, { name: 'withdraw', stateMutability: 'Nonpayable', type: 'Function' }],
};

const DArt = { contractAddress, contractAddressHex, contractAbi };
export default DArt;
