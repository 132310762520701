import {
  lazy, useReducer,
} from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { AppStateProvider } from './appContext';
import ToastAutoHide from './components/Common/UI/ToastAutoHide';
// import Home from './components/Pages/Home';
import { initialState, reducer } from './state';
import AppRouter from './Routers/Router';

import './App.css';

const ModelShowModal = lazy(() => import('./components/Common/ModelShowModal/ModelShowModal'));

function App() {
  // eslint-disable-next-line global-require
  window.Buffer = window.Buffer || require('buffer').Buffer;// FIXME: try to eliminate, but it is trontLink...
  const [appGlobalState, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <Helmet titleTemplate="Gulfstream - %s" />

      <div id="inner-container-dark">
        <AppStateProvider value={{
          variant: appGlobalState?.variant,
          appGlobalState,
          dispatch,
        }}
        >
          <Container className="inner-container-dark">
            {appGlobalState.toast !== null && <ToastAutoHide />}
            <ModelShowModal />
            <main style={{ minHeight: window.innerHeight / 1.30, padding: '2em 0px' }}>
              <AppRouter />
            </main>
          </Container>
        </AppStateProvider>
      </div>
    </>
  );
}

export default App;
