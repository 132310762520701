import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '../../appContext';
import { DArt } from '../../api/contracts';

export function useGetMinterStatus() {
  const [minterStatus, setMinterStatus] = useState(false);
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const { tronWeb } = window;
  useEffect(() => {
    const fetchMinterStatus = async () => {
      try {
        const createContract = await tronWeb.contract(
          DArt.contractAbi.entrys,
          DArt.contractAddress,
        );
        const isMinterRole = await createContract.isMinterRole();
        setMinterStatus(isMinterRole);
        dispatch({
          type: 'setToast',
          payload: {
            visible: 5,
            body: isMinterRole ? t('basic.toasts.4') : t('basic.toasts.5'),
            callee: t('pages.create-nft.btns.create-nft'),
          },
        });
      } catch (error) {
        dispatch({
          type: 'setToast',
          payload: {
            visible: 5,
            body: error.message,
            callee: 'DArt',
          },
        });
      }
    };

    fetchMinterStatus();
  }, [dispatch, t]);

  return { minterStatus };
}
