import { useContext } from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import AppContext from '../../../appContext';
import './Footer.css';

export default function Footer() {
  const { variant } = useContext(AppContext);

  return (
    <footer className={variant}>
      <Container>
        <hr className="separator" />
        <Row className="align-items-center">
          <Col>
            <Image src="/assets/website-footer-logo.svg" alt="footer-logo" />
          </Col>

          <Col className="d-flex gap-3 justify-content-end my-2">
            <a href="https://www.tiktok.com/@pro_gulfstream" target="_blank" rel="noreferrer">
              <Image width={40} height={40} src="/assets/tiktok-logo.svg" alt="tiktok-logo" />
            </a>

            <a href="https://twitter.com" target="_blank" rel="noreferrer">
              <Image width={40} height={40} src="/assets/twitter-logo.svg" alt="twitter-logo" />
            </a>

            <a href="https://t.me/" target="_blank" rel="noreferrer">
              <Image width={40} height={40} src="/assets/telegram-logo.svg" alt="telegram-logo" />
            </a>

            <a href="https://t.me/" target="_blank" rel="noreferrer">
              <Image width={40} height={40} src="/assets/youtube-icon.svg" alt="youtube-logo" />
            </a>
          </Col>

        </Row>

        <Row className="justify-content-center">
          { `GulfStream © 2021 - ${new Date().getFullYear()}`}
        </Row>
      </Container>
    </footer>
  );
}
