import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import { Spinner } from 'react-bootstrap';
import App from './App';

import './utils/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense
      fallback={(
        <div
          style={{ minHeight: '95vh', backgroundColor: 'rgba(15, 12, 12, 1)' }}
          className="d-flex justify-content-center align-items-center"
        >
          <Spinner animation="grow">
            <img src="logo16.svg" alt="logo" width={160} />
          </Spinner>
        </div>
      )}
    >
      <App />
    </Suspense>
  </React.StrictMode>,
);
