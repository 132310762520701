import { useContext, useEffect, useState } from 'react';
import { callApi } from '../../api/axios';
import AppContext from '../../appContext';

/**
 * Hook to fetch all tokens which are on the Market(BE)
 * @param {String} collectionNetwork network to look for tokens
 * @returns {Object} of shape { tokens:[], myPercent:Number, numOfCoins:{coin:amount}}
 */
export function useGetMarketItems(collectionNetwork, filterObj) {
  const [result, setResult] = useState({ tokens: [], total: 0 });
  const { dispatch } = useContext(AppContext);

  // fetching all the items from the market
  useEffect(() => {
    callApi({
      method: 'get',
      url: '/b/market/items',
      params: {
        filter_network: collectionNetwork,
        filter_status: 'created',
        filter_collection_id: filterObj.collectionID,
        filter_token_name: filterObj.tokenName,
        filter_page: filterObj.currentPage,
      },
    }).then(({ data }) => {
      // to count how many items of each coin does market have
      setResult((prev) => ({
        ...prev,
        tokens: data.rows,
        total: data.total,
      }));
    }).catch((error) => dispatch({
      type: 'setToast',
      payload: {
        visible: 5,
        body: error.message,
        callee: 'Market',
      },
    })).finally(() => {
      dispatch({ type: 'toggleLoadingState', payload: false });
    });
  }, [filterObj]);

  return result;
}
