import { FULL_COLECTION_DATA } from './constants';

/**
 * Function to hide the middle of the wallet link of some user
 * @param {String} link Wallet link
 * @param {Number} nOfSymbols N of symbols to show from both sides
 * @returns Wallet link with ... inside
 */
export const minimizeWalletLinks = (link, nOfSymbols = 4) => (link && link.length !== 0 ? `${link.substring(0, nOfSymbols)}...${link.substring(link.length - nOfSymbols)}` : '');

/**
 * Function to calculate withdrawal dates and
 * return the closest withdrawal date
 * @param {Object} depositObj All the info about the deposit
 * @param {Number} nMonths Amount of months
 * @returns {Date} Closest to now withdrawal date
 */
export const calculateClosestWithdrawDate = (depositObj, currentCollectionShownId) => {
  const secondsInDay = 1000 * 60 * 60 * 24;
  const exactMonthPerTariff = +FULL_COLECTION_DATA[currentCollectionShownId]
    .stakingTariffs[depositObj.tariffId].months;
  const allWithdrawDates = new Array(exactMonthPerTariff + 1).fill(null).map(
    (x, i) => new Date(depositObj.date + secondsInDay * 30 * i),
  );
  const closestWithdrawDate = allWithdrawDates.filter((dt) => Date.now() < dt)[0];
  // console.log(closestWithdrawDate);
  return closestWithdrawDate;
};

/**
 * Function to count the amount of {items} with specified key
 * @param {Array} array to iterate with
 * @param {String} key to group results by
 * @returns {Object}
 */
export const countItemsInArrByObjKey = (array, key) => array.reduce((allItems, item) => {
  if (item[key] in allItems) { // token_name
    // eslint-disable-next-line no-param-reassign
    allItems[item[key]] += 1;
  } else {
    // eslint-disable-next-line no-param-reassign
    allItems[item[key]] = 1;
  }
  // console.log(allItems);
  return allItems;
}, {});

/**
 * Function to filter an array of objects by 2 properties [token_name,market_sale]
 * @param {Array} array to apply filters
 * @param {Object} filterObj to get filters from
 * @returns {Array} of filtered elements
 */
export const filterByObjProps = (array, filterObj) => {
  const filteredByName = filterObj.token_name !== null
    ? array.filter((token) => (token.token_name === filterObj.token_name))
    : array;
  // console.log({ filteredByName });

  const filteredByMarket = filterObj.onMarket !== null
    ? array.filter((token) => ((token.marketitem_price !== null))) : array;
  // console.log({ filteredByMarket });

  let filteredArray;
  if (filteredByName.length > filteredByMarket.length
  ) {
    filteredArray = [...filteredByMarket];
  } else { filteredArray = [...filteredByName]; }
  return filteredArray;
};

/**
 * Function is the local replacement of tronWeb's one
 * @param {Number} num Price in SUN
 * @returns Real price in trx/usdt
 */
export const fromSun = (num) => +num / 1000000;

export const toSun = (num) => +num * 1000000;
