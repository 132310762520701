import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import { lazy } from 'react';
import { Footer, Header } from '../components/Layout';
import { ADMIN_WALLETS } from '../utils/constants';

const NFTMarket = lazy(() => import('../components/Pages/Market/Market'));
const NFTCollections = lazy(() => import('../components/Pages/Collections/Collections'));
const MyNFT = lazy(() => import('../components/Pages/MyNFT/MyNFT'));
const Staking = lazy(() => import('../components/Pages/Staking/Staking'));
const PageNotFound = lazy(() => import('../components/Pages/404/404'));
const Admin = lazy(() => import('../components/Pages/Admin/Admin'));
const CreateNFT = lazy(() => import('../components/Pages/CreateNFT/Create'));

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Header />

      <Routes>
        <Route index path="/" element={<Navigate to="/market" />} />
        {/* <Route index path="/" element={<Home />} /> */}

        <Route path="/market" element={<NFTMarket />} />
        <Route path="/market/:ref" element={<NFTMarket />} />
        <Route path="/stake" element={<Staking />} />
        <Route path="/my" element={<MyNFT />} />
        <Route path="/collections" element={<NFTCollections />} />
        <Route path="/create" element={<CreateNFT />} />
        {ADMIN_WALLETS.some(
          (wallet) => wallet === window.tronWeb?.defaultAddress?.base58,
        ) && (
        <Route
          index
          path="/admin"
          element={<Admin />}
        />
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}
