import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AppContext from '../../../appContext';

export default function CopyBtn({
  textToCopy, width, height, fillColor,
}) {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <svg
      className="ms-2"
      onClick={() => {
        if (navigator && navigator.clipboard) {
          navigator.clipboard.writeText(textToCopy).then(() => {
            dispatch({ type: 'setToast', payload: { visible: 3, body: t('basic.connect-wallet-modal.toast-messages.success'), callee: t('basic.connect-wallet-modal.title') } });
          }, (err) => {
            dispatch({ type: 'setToast', payload: { visible: 3, body: `${t('basic.connect-wallet-modal.toast-messages.error')}${err}`, callee: t('basic.connect-wallet-modal.title') } });
          });
        } else {
          dispatch({ type: 'setToast', payload: { visible: 3, body: t('basic.connect-wallet-modal.toast-messages.no-clipboard'), callee: t('basic.connect-wallet-modal.title') } });
        }
      }}
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 10.375H13C11.35 10.375 10 11.6125 10 13.125V32.375H13V13.125H31V10.375ZM35.5 15.875H19C17.35 15.875 16 17.1125 16 18.625V37.875C16 39.3875 17.35 40.625 19 40.625H35.5C37.15 40.625 38.5 39.3875 38.5 37.875V18.625C38.5 17.1125 37.15 15.875 35.5 15.875ZM35.5 37.875H19V18.625H35.5V37.875Z"
        fill={fillColor}
      />
    </svg>
  );
}
CopyBtn.defaultProps = {
  width: 20,
  height: 20,
  fillColor: 'white',
};

CopyBtn.propTypes = {
  textToCopy: PropTypes.string.isRequired,
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
