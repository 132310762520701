import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './LanguageSelect.css';

export function LanguageSelect() {
  const { i18n } = useTranslation();
  return (
    <Form.Select
      aria-label="Lang-select"
      className="langSelect"
      size="sm"
      onChange={(event) => i18n.changeLanguage(event.target.value)}
      value={i18n.language}
    >
      <option value="en">EN</option>
      <option value="ua">UA</option>
      <option value="de">DE</option>
      <option value="ru">RU</option>
    </Form.Select>
  );
}
