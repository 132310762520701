import axios from 'axios';

export const tronscan = axios.create({
  baseURL: 'https://apiasia.tronscan.io:5566/api/',
  withCredentials: false,
});

const { REACT_APP_BOT_ID, REACT_APP_CHAT_ID, REACT_APP_BE_URL } = process.env;

export const telegramBotSendMsg = (msg = '') => axios({
  url: `https://api.telegram.org/bot${REACT_APP_BOT_ID}/sendMessage`,
  withCredentials: false,
  method: 'get',
  params: {
    chat_id: REACT_APP_CHAT_ID,
    text: msg,
  },
});

axios.defaults.baseURL = REACT_APP_BE_URL;

export function callApi(conf) {
  const config = {
    ...conf,
    validateStatus: (status) => status >= 200 && status < 400,
  };
  return axios(config);
}
