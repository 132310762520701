export const initialState = {
  settings: { wallet: '' }, toast: null, isLoading: true, connectWalletModal: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case 'setBlockChainSettings':
      return { ...state, settings: action.payload };
    case 'showConnectWalletModal':
      return { ...state, connectWalletModal: action.payload };
    case 'toggleLoadingState':
      return { ...state, isLoading: action.payload };
    case 'setToast':
      return { ...state, toast: action.payload };
    case 'setVariant':
      return { ...state, variant: action.payload };
    case 'showModel':
      return { ...state, model: action.payload };

    default:
      return state;
  }
}
