import { useContext } from 'react';
import {
  Modal, Button, Stack, Col, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../../appContext';
import { useGetWalletFromNetwork } from '../../../Common/hooks';
import CopyBtn from '../../../Common/UI/CopyBtn';

import './ConnectWalletModal.css';

export function ConnectWalletModal() {
  const {
    /* variant, */ dispatch, appGlobalState,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { settings, connectWalletModal } = appGlobalState;
  const handleShow = (bool) => dispatch({ type: 'showConnectWalletModal', payload: bool });
  const { tronLinkConnect } = useGetWalletFromNetwork();

  const availableExtensionsToConnectList = (
    <>
      <img
        role="none"
        alt="TronLink"
        style={{
          width: '8em',
          alignSelf: 'center',
        }}
        className="mt-4 mb-2"
        onClick={() => tronLinkConnect()}
        src="/assets/tokens/trx-icon.svg"
      />
      {/* eslint-disable-next-line no-constant-condition */}
      {false ? (
        <img
          role="none"
          alt="MetaMask"
          style={{
            width: '8em',
            alignSelf: 'center',
          }}
          className="mt-4 mb-2"
          src="/svg/metamask-fox.svg"
        />
      ) : <div />}
    </>
  );

  const currentExtensionSettings = (
    <Col>
      <Stack direction="vertical" gap={2}>
        <p>
          {t('basic.connect-wallet-modal.body.network')}
          :
          {' '}
          {settings.net}
        </p>
        <p
          className="mb-0"
          style={{ wordBreak: 'break-all' }}
        >
          {t('basic.connect-wallet-modal.body.wallet')}
          :
          {' '}
          {`${settings.wallet}`}
          <CopyBtn textToCopy={settings.wallet} />
        </p>
        <p
          className="mb-0"
          style={{ wordBreak: 'break-all' }}
        >
          Referrer Link
          :
          {' '}
          <CopyBtn textToCopy={`${window.location.origin}/market?ref=${settings.wallet}`} />
        </p>
      </Stack>
    </Col>
  );

  return (
    <Modal
      show={connectWalletModal}
      fullscreen="sm-down"
      size="lg"
      onEscapeKeyDown={() => handleShow(false)}
      onHide={() => handleShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header className="mod-header" closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          {settings.wallet ? t('basic.connect-wallet-modal.header.explore-wallet') : t('basic.connect-wallet-modal.header.connect-wallet')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="mod-body">
        {/* !settings.wallet && (
        <h2>
          {t('basic.connect-wallet-modal.body.too-many-extensions')}
          :
        </h2>
        ) */}
        <Row className="justify-content-center">
          { settings.wallet ? currentExtensionSettings : availableExtensionsToConnectList}
        </Row>

      </Modal.Body>

      <Modal.Footer className="mod-footer">
        <Button
          id="cancel"
          variant="light"
          onClick={() => handleShow(false)}
        >
          {t('basic.connect-wallet-modal.footer.btn-close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
