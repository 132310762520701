/* eslint-disable no-underscore-dangle */
import { useContext, useEffect, useState } from 'react';
import { callApi } from '../../api/axios';
import AppContext from '../../appContext';

/**
 * Hook to fetch the collection from BE
 * @param {Number} collectionId ID of the collection
 * @param {String} collectionNetwork Where the collection exists
 * @returns Array of tokens and the maximum pages amount
 */
export function useGetCollectionItems(collectionNetwork, filterObj) {
  const [tokens, setTokens] = useState([]);

  const [maxPage, setmaxPage] = useState(0);
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    dispatch({ type: 'toggleLoadingState', payload: false });
    if (collectionNetwork && filterObj.collectionID) {
      callApi({
        method: 'get',
        url: 'b/tokens/collection',
        params: {
          filter_network: collectionNetwork,
          filter_collection_id: filterObj.collectionID,
          filter_token_name: filterObj.tokenName,
          filter_page: filterObj.currentPage,
        },
      }).then(({ data }) => {
        setTokens(data);
        setmaxPage(Math.round(data.length / 20));// FIXME: useless
      }).catch((error) => dispatch({
        type: 'setToast',
        payload: {
          visible: 5,
          body: error.message,
          callee: 'Collections',
        },
      }));
    }
  }, [collectionNetwork, filterObj]);

  return { tokens, maxPage };
}
