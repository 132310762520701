/* eslint-disable no-underscore-dangle */
import { useContext, useEffect, useState } from 'react';
import { callApi } from '../../api/axios';
import AppContext from '../../appContext';

/**
 * Hook to fetch the list of existing collections
 * @returns {Array}
 */
export function useGetBECollections(collectionNetwork = 'tronmainnet') {
  const [collections, setCollections] = useState([]);
  const { dispatch, appGlobalState } = useContext(AppContext);
  // get collections
  useEffect(() => {
    if (collections.length === 0) {
      callApi({
        method: 'get',
        url: 'b/collections',
        params: {
          filter_network: collectionNetwork,
        },
      }).then(({ data }) => {
        // console.log('collections', data);
        setCollections(data);
      }).catch((error) => dispatch({
        type: 'setToast',
        payload: {
          visible: 5,
          body: error.message,
          callee: 'Collections',
        },
      }));
    }
  }, [collections, appGlobalState]);
  return collections;
}
