/* eslint-disable no-await-in-loop */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';
import AppContext from '../../appContext';
import { PremiumPoolContract } from '../../api/contracts';
import { fromSun } from '../helpers';

export function useGetPremiumPoolTableRows(wallet) {
  const { dispatch } = useContext(AppContext);
  const [rows, setRows] = useState([]);
  const { tronWeb } = window;
  // eslint-disable-next-line no-underscore-dangle
  const toDecimal = (value) => tronWeb.toDecimal(value?._hex);

  const fetchData = async (depositIndex, delay) => {
    try {
      const stakingContract = await tronWeb.contract(
        PremiumPoolContract.contractAbi.entrys,
        PremiumPoolContract.contractAddress,
      );
      const depositTariffId = await stakingContract.getDepositTariff(wallet, depositIndex).call();
      await new Promise((resolve) => setTimeout(resolve, delay));

      const depositDate = await stakingContract.getDepositAt(wallet, depositIndex).call();
      await new Promise((resolve) => setTimeout(resolve, delay));

      const depositAmount = await stakingContract.getDepositAmount(wallet, depositIndex).call();
      await new Promise((resolve) => setTimeout(resolve, delay));

      const depositPercentsMonthly = await stakingContract.calcDepositPercentsMonthly(
        wallet,
      ).call();
      await new Promise((resolve) => setTimeout(resolve, delay));

      const depositPercentsSeconds = await stakingContract.calcDepositPercentsEverySecond(
        wallet,
      ).call();
      await new Promise((resolve) => setTimeout(resolve, delay));

      const depositPaidOut = await stakingContract.getDepositPaidOut(wallet, depositIndex).call();
      await new Promise((resolve) => setTimeout(resolve, delay));

      const depositClosed = await stakingContract.getDepositClosed(wallet, depositIndex).call();

      setRows((prev) => ([...prev, {
        depositId: depositIndex,
        tariffId: depositTariffId,
        date: toDecimal(depositDate) * 1000,
        wholeDeposit: fromSun(toDecimal(depositAmount)),
        monthly: fromSun(toDecimal(depositPercentsMonthly[depositIndex])),
        income: fromSun(toDecimal(depositPercentsSeconds[depositIndex])),
        paidOut: fromSun(toDecimal(depositPaidOut)),
        isClosed: depositClosed,
      }]));
    } catch (error) {
      console.error(error);
    }
  };

  const delayedForLoop = async (iterations, delay) => {
    for (let currentIteration = 0; currentIteration < iterations; currentIteration += 1) {
      await fetchData(currentIteration, 200);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
    dispatch({ type: 'toggleLoadingState', payload: false });
  };

  useEffect(() => {
    if (wallet) {
      const fetchInvestorData = async () => {
        try {
          const stakingContract = await tronWeb.contract(
            PremiumPoolContract.contractAbi.entrys,
            PremiumPoolContract.contractAddress,
          );
          const { numDeposits } = await stakingContract.methods.investors(wallet).call();
          delayedForLoop(numDeposits, 1000);
        } catch (error) {
          console.error(error);
        }
      };
      fetchInvestorData();
    }
  }, [wallet]);
  return { rows };
}
