/* eslint-disable no-underscore-dangle */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AppContext from '../../appContext';

/**
 * Hook for the checking of the available networks [trx/web3] and for connecting the
 * appropriate wallets
 * @returns {Object}
 */
export function useGetWalletFromNetwork() {
  const { dispatch, appGlobalState } = useContext(AppContext);
  const { pathname } = useLocation();
  const [w, setWallet] = useState({ extension: undefined, net: undefined, wallet: '' });
  const [doNotDisturb, setDoNotDisturb] = useState(false);
  const { net, wallet } = appGlobalState.settings;
  const { t } = useTranslation();

  // connect to tronLink
  function tronLinkConnect() {
    const { tronWeb } = window;
    if (tronWeb && tronWeb.ready) {
      if (typeof tronWeb.defaultAddress.base58 !== 'boolean') {
      // boolean is when user declines the wallet connection
        dispatch({
          type: 'setBlockChainSettings',
          payload: {
            extension: 'tronLink',
            net: 'tronmainnet',
            wallet: tronWeb.defaultAddress.base58,
          },
        });
        setWallet({
          extension: 'tronLink',
          net: 'tronmainnet',
          wallet: tronWeb.defaultAddress.base58,
        });
        dispatch({ type: 'showConnectWalletModal', payload: false });
      } else { setDoNotDisturb(true); }
    } else {
      // console.log('show toast that log into tronLink');
      dispatch({
        type: 'setToast',
        payload: {
          visible: 3,
          body: t('basic.toasts.1'),
          callee: 'Gulfstream',
        },
      });
    }
  }

  // check the connection with extensions
  useEffect(() => {
    const loadWatcher = setInterval(() => {
      const {
        tronWeb, /*  web3, */
      } = window;

      if (wallet.length === 0 && !doNotDisturb) {
        if ((tronWeb /* && tronWeb.ready */) /* && web3 */) {
          // dispatch({ type: 'showConnectWalletModal', payload: true });
        } else if (!['/', '/market', '/collections'].includes(pathname)) {
          // console.log('show toast that we are trying to find the extensions');
          dispatch({
            type: 'setToast',
            payload: {
              visible: 0.5,
              body: t('basic.toasts.2'),
              callee: 'Gulfstream',
            },
          });
        }

        if (tronWeb/* &&  tronWeb.ready */ /*  && !web3 */) {
          // console.log('connect tronLink');
          tronLinkConnect();
        }

        /* if (web3 && !tronWeb && !tronWeb.ready ) {
          // console.log('connect web3');
          metaConnect();
        } */
      } // else === we have wallet, watch changing the network
    }, 2 * 1000);

    return () => clearInterval(loadWatcher);
  }, [wallet, net, doNotDisturb]);

  return { ...w, tronLinkConnect/* , metaConnect  */ };
}
