const contractAddress = 'TFgMrJAK5dCpZ5NwhmzoWST6E3qqVTzcpH';
const contractAddressHex = '4154494b6f3ce4d597e269a45af4f269f510a1fea6';
const contractAbi = {
  entrys: [{ inputs: [{ name: '_nftContract', type: 'address' }], stateMutability: 'Nonpayable', type: 'Constructor' }, { inputs: [{ indexed: true, name: 'itemId', type: 'uint256' }, { indexed: true, name: 'tokenId', type: 'uint256' }, { indexed: true, name: 'seller', type: 'address' }, { name: 'owner', type: 'address' }, { name: 'price', type: 'uint256' }, { name: 'sold', type: 'bool' }, { name: 'listed', type: 'bool' }], name: 'MarketItemCreated', type: 'Event' }, { inputs: [{ indexed: true, name: 'itemId', type: 'uint256' }, { indexed: true, name: 'wallet', type: 'address' }], name: 'MarketItemSaleStopped', type: 'Event' }, { inputs: [{ indexed: true, name: 'itemId', type: 'uint256' }, { indexed: true, name: 'seller', type: 'address' }, { indexed: true, name: 'owner', type: 'address' }, { name: 'price', type: 'uint256' }], name: 'MarketItemSold', type: 'Event' }, {
    outputs: [{ type: 'uint256' }], name: 'REF_BONUS_SIZE', stateMutability: 'View', type: 'Function',
  }, { name: 'SilentModeOff', stateMutability: 'Nonpayable', type: 'Function' }, { name: 'SilentModeOn', stateMutability: 'Nonpayable', type: 'Function' }, {
    inputs: [{ name: '_owner', type: 'address' }], name: 'TransferOwnership', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'tokenId', type: 'uint256' }, { name: 'price', type: 'uint256' }], name: 'createMarketItem', stateMutability: 'Payable', type: 'Function',
  }, {
    inputs: [{ name: 'itemId', type: 'uint256' }, { name: 'referrer', type: 'address' }], name: 'createMarketSale', stateMutability: 'Payable', type: 'Function',
  }, {
    inputs: [{ name: 'itemId', type: 'uint256' }, { name: 'price', type: 'uint256' }], name: 'editMarketItemPrice', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'tokenId', type: 'uint256' }], name: 'fetchLastMarketItemIdOfListedToken', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'itemId', type: 'uint256' }], name: 'fetchMarketItemListed', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'address' }], inputs: [{ name: 'itemId', type: 'uint256' }], name: 'fetchMarketItemOwner', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'itemId', type: 'uint256' }], name: 'fetchMarketItemPrice', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'address' }], inputs: [{ name: 'itemId', type: 'uint256' }], name: 'fetchMarketItemSeller', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'itemId', type: 'uint256' }], name: 'fetchMarketItemSold', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'itemId', type: 'uint256' }], name: 'fetchMarketItemTokenId', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256[]' }], inputs: [{ name: 'seller', type: 'address' }], name: 'fetchTokensListedBySeller', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'get_LISTING_PRICE', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'get_MINIMUM_PRICE', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'get_SELL_FEE', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], name: 'get_TOKEN_CAN_BE_LISTED_MULTIPLE', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ name: 'itemId', type: 'uint256' }, { name: 'tokenId', type: 'uint256' }, { name: 'seller', type: 'address' }, { name: 'owner', type: 'address' }, { name: 'price', type: 'uint256' }, { name: 'sold', type: 'bool' }, { name: 'listed', type: 'bool' }], inputs: [{ type: 'uint256' }], name: 'idToMarketItem', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bytes4' }], inputs: [{ name: '_operator', type: 'address' }, { name: '_from', type: 'address' }, { name: '_tokenId', type: 'uint256' }, { name: '_data', type: 'bytes' }], name: 'onTRC721Received', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: 'itemId', type: 'uint256' }], name: 'pauseListingMarketItem', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: 'itemId', type: 'uint256' }], name: 'resumeListingMarketItem', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: 'percent', type: 'uint256' }], name: 'setRefBonus', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: '_LISTING_PRICE', type: 'uint256' }], name: 'set_LISTING_PRICE', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: '_SELL_FEE', type: 'uint256' }], name: 'set_SELL_FEE', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: '_TOKEN_CAN_BE_LISTED_MULTIPLE', type: 'bool' }], name: 'set_TOKEN_CAN_BE_LISTED_MULTIPLE', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: '_editPriceUser', type: 'address' }], name: 'set_editPriceUser', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: '_nftContract', type: 'address' }], name: 'set_nftContract', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    inputs: [{ name: 'itemId', type: 'uint256' }], name: 'stopMarketSale', stateMutability: 'Nonpayable', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ type: 'uint256' }], name: 'tokenSalesStatistics', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: 'to', type: 'address' }, { name: 'amount', type: 'uint256' }], name: 'withdraw', stateMutability: 'Nonpayable', type: 'Function',
  }],
};

const MarketContractTRX = { contractAddress, contractAddressHex, contractAbi };
export default MarketContractTRX;
