import { useContext, useState } from 'react';
import {
  Container, Navbar, Nav, NavLink, Stack,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConnectWalletModal } from './ConnectWalletModal/ConnectWalletModal';
import AppContext from '../../../appContext';
import { minimizeWalletLinks } from '../../../utils/helpers';
import { ADMIN_WALLETS, ROUTES } from '../../../utils/constants';
import { LanguageSelect } from './LanguageSelect/LanguageSelect';
import { useGetUsersWallet } from '../../../utils/hooks';

import './Header.css';

export default function Header() {
  const { pathname } = useLocation();
  const [exp, setExp] = useState(false);
  const { t } = useTranslation();
  const { dispatch } = useContext(AppContext);
  const { userWallet } = useGetUsersWallet();

  return (
    <Navbar
      expand="lg"
      collapseOnSelect
      onToggle={() => setExp((prev) => !prev)}
      expanded={exp}
      variant="dark"
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            src="/assets/website-logo.svg"
            alt="gulfstream-logo"
            width={210}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav " />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto gap-3 align-items-center" justify>
            {ROUTES.map((link) => (
              <Nav.Item key={link}>
                <NavLink
                  active={pathname.substring(1).startsWith(link)}
                  href={`/${link}`}
                >
                  {t(`basic.header.routes.${link}`)}
                </NavLink>
              </Nav.Item>
            ))}

            {ADMIN_WALLETS.some(
              (w) => w === window.tronWeb?.defaultAddress?.base58,
            ) && (
            <Nav.Item key="admin">
              <NavLink
                  // className="ml-2 mr-2"
                active={pathname === '/admin'}
                href="/admin"
              >
                {/* t(`basic.header.routes.${link}`) */}
                Admin
              </NavLink>
            </Nav.Item>
            )}
          </Nav>

          <Stack direction="horizontal" gap={3} className="justify-content-center">

            <LanguageSelect />

            <ConnectWalletModal />

            <div
              id="wallet-connect"
              className="align-items-center"
              style={{ display: 'none', cursor: 'pointer' }}
              data-testid="wallet-connect"
              aria-hidden
              onClick={() => { dispatch({ type: 'showConnectWalletModal', payload: true }); setExp(false); }}
            >
              <img src="/assets/wallet-icon.svg" alt="wallet" width={45} className="align-self-center" />
              <h5 className="p-0 m-0" style={{ color: 'white' }}>
                {userWallet ? minimizeWalletLinks(userWallet, 3) : t('basic.header.buttons.connect-wallet')}
              </h5>
            </div>
          </Stack>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
