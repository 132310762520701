import { useEffect, useState } from 'react';
import { DArt } from '../../api/contracts';

/**
 * Hook to check if we can operate with that NFT
 * @param {Number} tokenId ID to check
 * @returns {Boolean}
 */
export function useGetItemIsFrozen(nft) {
  const [frozen, setIsFrozen] = useState(false);
  const { tronWeb } = window;
  useEffect(() => {
    const checkIsFrozen = async () => {
      try {
        const createContract = await tronWeb.contract(
          DArt.contractAbi.entrys,
          DArt.contractAddress,
        );
        const isFrozen = await createContract.getTokenIsFrozen(nft.token_nft_id).call();
        setIsFrozen(isFrozen);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    checkIsFrozen();
  }, [nft.token_nft_id]);

  return frozen;
}
