import { useContext, useEffect, useState } from 'react';
import { callApi } from '../../api/axios';
import AppContext from '../../appContext';
import { DArt } from '../../api/contracts';

/**
 * Hook to fetch from smart-contract and BE all the user's tokens
 * @returns {Array}
*/
export function useGetAllUsersTokens(neededWallet, filterObj) {
  const [myTokens, setMyTokens] = useState([]);
  const { dispatch } = useContext(AppContext);
  const { tronWeb } = window;

  useEffect(() => {
    const fetchUserTokens = async () => {
      try {
        const createContract = await tronWeb.contract(
          DArt.contractAbi.entrys,
          DArt.contractAddress,
        );
        const userTokens = await createContract.methods.getUserTokens(neededWallet).call();

        const userTokensOnSell = await callApi({
          method: 'get',
          url: '/b/market/items',
          params: {
            filter_status: 'created',
            filter_seller: neededWallet,
          },
        });

        const idsOfSellingItems = userTokensOnSell.data.rows.map((tok) => tok.token_nft_id);
        const userTokensTogether = [...userTokens, ...idsOfSellingItems];

        const { data } = await callApi({
          method: 'get',
          url: 'b/tokens/my',
          params: {
            filter_network: 'tronmainnet',
            filter_nft_id: filterObj.onMarket === 1 ? idsOfSellingItems.toString()
              : userTokensTogether.toString(),
            filter_collection_id: filterObj.collectionID,
            filter_token_name: filterObj.tokenName,
            filter_page: filterObj.currentPage,
          },
        });

        setMyTokens(data);
      } catch (error) {
        const errorMessage = error.response?.data.shift().msg || error.message;
        dispatch({
          type: 'setToast',
          payload: {
            visible: 3,
            body: errorMessage,
            callee: 'Gulfstream',
          },
        });
      } finally {
        dispatch({ type: 'toggleLoadingState', payload: false });
      }
    };
    if (neededWallet) {
      fetchUserTokens();
    }
  }, [neededWallet, filterObj, dispatch]);

  return myTokens;
}
