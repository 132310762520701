const contractAddress = 'TP4AwG55auc2MMHZLc3dmRXh7G3afkTksC';
const contractAddressHex = '4154494b6f3ce4d597e269a45af4f269f510a1fea6';
const contractAbi = {
  entrys: [{ stateMutability: 'Nonpayable', type: 'Constructor' }, { inputs: [{ indexed: true, name: '_user', type: 'address' }, { name: 'tariff', type: 'uint256' }, { indexed: true, name: '_amount', type: 'uint256' }], name: 'DepositEvent', type: 'Event' }, { inputs: [{ name: 'log', type: 'string' }, { name: 'data', type: 'uint256' }], name: 'debugEvent', type: 'Event' }, { inputs: [{ indexed: true, name: '_user', type: 'address' }, { indexed: true, name: '_uid', type: 'uint256' }], name: 'investorExistEvent', type: 'Event' }, { inputs: [{ indexed: true, name: '_user', type: 'address' }], name: 'registerEvent', type: 'Event' }, { inputs: [{ indexed: true, name: '_user', type: 'address' }, { indexed: true, name: '_amount', type: 'uint256' }], name: 'withdrawEvent', type: 'Event' }, {
    outputs: [{ type: 'uint256' }], name: 'BONUS_ROUND', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: 'to', type: 'address' }, { name: 'amount', type: 'uint256' }], name: 'TransferTo', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ name: 'success', type: 'bool' }], inputs: [{ name: 'addr', type: 'address' }], name: 'addAddressToBlacklist', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ type: 'address' }], name: 'blacklisted', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256[]' }], inputs: [{ name: 'user', type: 'address' }], name: 'calcDepositPercentsEverySecond', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256[]' }], inputs: [{ name: 'user', type: 'address' }], name: 'calcDepositPercentsMonthly', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: '_tariff', type: 'uint8' }], name: 'deposit', stateMutability: 'payable', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: '_tariff', type: 'uint8' }, { name: '_wallet', type: 'address' }], name: 'depositForUser', stateMutability: 'payable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], name: 'eol', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'user', type: 'address' }, { name: 'did', type: 'uint256' }], name: 'getDepositAmount', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'user', type: 'address' }, { name: 'did', type: 'uint256' }], name: 'getDepositAt', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: 'user', type: 'address' }, { name: 'did', type: 'uint256' }], name: 'getDepositClosed', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'user', type: 'address' }, { name: 'did', type: 'uint256' }], name: 'getDepositPaidOut', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint8' }], inputs: [{ name: 'user', type: 'address' }, { name: 'did', type: 'uint256' }], name: 'getDepositTariff', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256[]' }], inputs: [{ name: 'user', type: 'address' }], name: 'getDepositsWithdrawn', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ name: 'id', type: 'uint32' }, { name: 'numDeposits', type: 'uint32' }, { name: 'totalDepositPercents', type: 'uint256' }, { name: 'totalDepositPrincipals', type: 'uint256' }, { name: 'invested', type: 'uint256' }, { name: 'paidAt', type: 'uint256' }, { name: 'withdrawn', type: 'uint256' }], inputs: [{ type: 'address' }], name: 'investors', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ name: 'success', type: 'bool' }], inputs: [{ name: 'addr', type: 'address' }], name: 'removeAddressFromBlacklist', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'max', type: 'uint256' }], name: 'setBonusRound', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'uint256' }], inputs: [{ name: 'min', type: 'uint256' }], name: 'setMinDeposit', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], inputs: [{ name: '_tariff', type: 'uint256' }, { name: '_status', type: 'bool' }], name: 'setTariffStatus', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], name: 'state', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ name: 'time', type: 'uint256' }, { name: 'percent', type: 'uint256' }, { name: 'status', type: 'bool' }], inputs: [{ type: 'uint256' }], name: 'tariffs', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'totalDeposits', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint256' }], name: 'totalInvested', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint32' }], name: 'totalInvestors', stateMutability: 'View', type: 'Function',
  }, {
    outputs: [{ type: 'uint32' }], name: 'totalUsers', stateMutability: 'View', type: 'Function',
  }, {
    inputs: [{ name: 'newOwner', type: 'address' }], name: 'transferOwnership', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], name: 'turnOff', stateMutability: 'nonpayable', type: 'function',
  }, {
    outputs: [{ type: 'bool' }], name: 'turnOn', stateMutability: 'nonpayable', type: 'function',
  }, { name: 'withdraw', stateMutability: 'nonpayable', type: 'function' }],
};

const StakingContractTRX = { contractAddress, contractAddressHex, contractAbi };
export default StakingContractTRX;
