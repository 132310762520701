/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';

/**
 * Hook to get the last 10 needed events from SmartContract
 * @param {String} eventName Needed event from contract
 * @param {String} contractAddress Address of the contract
 * @returns {Array} of {user,amount,dateTime,transaction}
 */
export function useGetLastContractEvents(contractAddress) {
  const [lastEvents, setLastEvents] = useState();
  // get list of specified events of the current Contract
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ contractAddress }),
  };
  const fetchData = () => {
    fetch('https://apilist.tronscanapi.com/api/contracts/smart-contract-triggers-batch?fields=hash,method', options)
      .then((response) => response.json())
      .then((response) => {
        setLastEvents(response.event_list.map(
          ({
            result, block_timestamp, event_name, transaction_id,
          }) => ({
            user: window.tronWeb ? window.tronWeb.address.fromHex(result._user) : result._user,
            amount: result._amount / 1000000,
            dateTime: new Date(block_timestamp).toLocaleString(),
            name: event_name.split('Event').shift(),
            transaction: transaction_id,
          }),
        ).filter((event) => event.name === 'withdraw'));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('err', err));
  };
  useEffect(() => {
    if (contractAddress) {
      fetchData();// first init fetch
      setInterval(() => { // 5 minutes interval to update transactions list
        fetchData();
      }, 1000 * 60 * 5);
    }
  }, [contractAddress]);
  return lastEvents;
}
